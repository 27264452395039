.c-post-meta {
    @include body-sans-5;
    font-weight: 350;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    white-space: nowrap;

    .o-icon {
        margin-right: var(--space-xxs);
    }
}

.c-post-meta--calendar {

    span {
        white-space: nowrap;
    }
}

.c-post__meta-item--date__time {
    text-transform: initial;

    @include respond-above(sm) {
        display: none;
    }
}

    .c-post-meta--time,
    .c-post-meta--read {

        @include respond-below(sm) {
            display: none;
        }
    }

.c-post__meta-header--article {

    @media (min-width: 380px) {
        display: flex;
        flex-wrap: wrap;
        
        > * + * {
            margin-left: var(--space-md);
        }
    }

    .c-post-meta--read {
        display: flex;

        @include respond-above(sm) { 
            display: none;
        }
    }
}