.c-tag {
    @include body-sans-5;
    font-weight: 350;

    &::before {
        content: '#';
        color: var(--color-grey-500);
    }
}

.c-tag-list {
    margin-top: var(--space-lg);
    
    @include respond-below(sm) {
        border-top: rem(1) solid var(--color-text-400);
        padding-top: var(--space-md);
        margin-bottom: 0;
        order: 1;

        + * {
            order: 3;
        }
    }

    @include respond-above(sm) {      
      margin-top: 0;
    }

    @include respond-above(lg) {
        padding-left: var(--space-unit);
    }

    ul {
        margin-left: calc(var(--space-xxs)*-1);
    }
}

.c-tag-list__item {
    display: inline-block;
    margin: var(--space-xxs);
}