.o-stack > * + * {
  margin-top: var(--space-lg);
}

  .o-stack--xxs > * + * {
    margin-top: var(--space-xxxs);
  }

  .o-stack--xs > * + * {
    margin-top: var(--space-xs);
  }

  .o-stack--sm > * + * {
    margin-top: var(--space-unit);
  }
  
  .o-stack--lg > * + * {
    margin-top: var(--space-xl);
  }

  .o-stack--xl > * + * {
    margin-top: rem(80);
  }

  .o-stack--xxl > * + * {
    margin-top: var(--space-xxxl);
  }

  
//Responsive stack classes

@include respond-above(sm) {

  .o-stack--0\@sm  > * + * {
    margin-top: 0;
  }
  
  .o-stack--xxs\@sm  > * + * {
    margin-top: var(--space-xxxs);
  }

  .o-stack\@sm  > * + * {
    margin-top: var(--space-lg);
  }

  .o-stack--lg\@sm  > * + * {
    margin-top: var(--space-xl);
  }

  .o-stack--xl\@sm  > * + * {
    margin-top: var(--space-xxl);
  }

  .o-stack--xxl\@sm  > * + * {
    margin-top: var(--space-xxxl);
  }
}
  
@include respond-above(md) {

  .o-stack--xxs\@md  > * + * {
    margin-top: var(--space-xxxs);
  }

  .o-stack\@md  > * + * {
    margin-top: var(--space-lg);
  }

  .o-stack--lg\@md  > * + * {
    margin-top: var(--space-xl);
  }

  .o-stack--xl\@md  > * + * {
    margin-top: var(--space-xxl);
  }

  .o-stack--xxl\@md  > * + * {
    margin-top: var(--space-xxxl);
  }
}

@include respond-above(lg) {

  .o-stack--xxs\@lg  > * + * {
    margin-top: var(--space-xxxs);
  }

  .o-stack\@lg  > * + * {
    margin-top: var(--space-lg);
  }

  .o-stack--lg\@lg  > * + * {
    margin-top: var(--space-xl);
  }

  .o-stack--xl\@lg  > * + * {
    margin-top: var(--space-xxl);
  }

  .o-stack--xxl\@lg  > * + * {
    margin-top: var(--space-xxxl);
  }
}



.o-stack__horizontal > * + * {
  margin-left: var(--space-lg);
}

  .o-stack__horizontal--sm > * + * {
    margin-left: var(--space-unit);
  }

  .o-stack__horizontal--xs > * + * {
    margin-left: var(--space-xs);
  }
  