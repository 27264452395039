/* ==========================================================================
   #Article grid classes
   ========================================================================== */

.o-pull {
  
  @include respond-above(sm) {
    width: var(--w-art-col-2);
    margin-top: rem(60); 
  }

  @include respond-between(sm, md) {
    &.o-pull-right {
      width: 100%;
    }
  }

  @include respond-above(lg) {
    width: var(--w-art-col-4);
  }

  @include respond-above(xl) {
    width: var(--w-art-col-4);
  }
}

.o-pull--inset {
  
  @include respond-above(md) {
    width: 86.9318%;
    margin-top: rem(60);
  }
}
  .o-pull--inset-sm {

    @include respond-above(md) {
      width: var(--w-6-col);

      &.o-pull-right {
        width: var(--w-4-col);
      }
    }

    @include respond-above(lg) {
      
      &,
      &.o-pull-right {
        width: var(--w-6-col--xl);
      }
    }
  }

.o-pull--left {

  @include respond-above(sm) {
    margin-left: calc((var(--w-art-col-2) + var(--space-lg)) * -1);
    float: left;
  }
  
  @include respond-above(md) {
    margin-right: var(--space-lg);
  }
  
  @include respond-above(lg) {
    margin-left: calc((var(--w-art-col-4) + var(--space-lg)) * -1);
  }
}

.o-pull--right {
  
  @include respond-above(md) {
      margin-right: 0;
      margin-left: var(--space-unit);
      float: right;
  }

  @include respond-above(lg) {
    margin-right: calc((var(--w-art-col-4) + var(--space-lg)) * -1);
    margin-left: var(--space-lg);
  }
}

.o-pull--full {
  
  @include respond-above(sm) {
    margin-left: calc((var(--w-art-col-2) + var(--space-lg)) * -1);
    width: calc(100% + var(--w-art-col-2) + var(--space-lg));
  }
  
  @include respond-above(lg) {
    margin-left: calc((var(--w-art-col-4) + var(--space-lg)) * -1);
    width: calc(100% + (var(--w-art-col-4) * 2) + (var(--space-lg) * 2));
  }
}

.o-pull--full-bleed {
  transform: translateX(calc(var(--space-unit) * -1));
  width: calc(100% + (var(--space-unit) * 2));

  
  @include respond-above(sm) {
    transform: translateX(calc(var(--space-md) * -1));
    margin-left: calc((var(--w-art-col-2) + var(--space-lg)) * -1);
    width: calc(100% + var(--w-art-col-2) + var(--space-lg) + (var(--space-md) * 2));
  }

  @include respond-above(lg) {
    margin-left: calc((var(--w-art-col-4) + var(--space-lg)) * -1);
    width: calc(100% + (var(--w-art-col-4) * 2) + (var(--space-lg) * 2) + (var(--space-md) * 2));
  }
  
  @include respond-above(lg) {
    //margin-left: calc((var(--w-art-col-4) + var(--space-lg)) * -1);
    //width: calc(100% + (var(--w-art-col-4) * 2) + (var(--space-lg) * 2));
  }
}

.o-content [class^="o-pull"]:first-child:not(.o-pull--full) {

  @include respond-above(sm) {
    margin-top: var(--space-xs);
  }

  + * {

    @include respond-above(sm) {
      margin-top: 0 !important;
    }
  }
}