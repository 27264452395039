.o-page {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    min-height: 100vh;
    background-color: var(--color-bg);
    padding: var(--space-md) env(safe-area-inset-right) env(safe-area-inset-bottom);

    @include respond-above(lg) {
        padding-top: var(--space-xl);
     }
    
    .c-nav {
        position: fixed; 
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    
        @include respond-above(sm) {
            position: static;
        }
    }
}