.o-figure {
    //
}

.o-figure--full {
    position: relative; 
    z-index: 5;

    @include respond-above(sm) {
        left: 30%;
        width: 100vw;
        transform: translateX(-50%);
    
        .o-figure__caption {
            text-align: center;
        }
    }

    @include respond-above(md) {
        left: 36%;
    }

    @include respond-above(lg) {
        left: 44%;
    }

    @include respond-above(xl) {
        left: 50%;
    }
}


.o-figure--quote {
    position: relative;
    padding-left: var(--space-xl);
    @include quote;
    line-height: 1.3;
    background-color: var(--color-bg);

    @include respond-above(lg) {
        margin-top: rem(40);
        padding: var(--space-unit) 0 var(--space-unit) var(--space-xl);
    }

    &::before {
        content: '“';
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(100);
        font-weight: 500;
        color: var(--color-text-600);
        line-height: .9;
        
        @include respond-above(lg) {
            line-height: 1.22;
        }
    }

    cite {
        font-style: normal;
        font-weight: 400;
    }
}

    .o-figure__caption {
        @include body-sans-5;
        padding: var(--space-xxs) 0;
        color: var(--color-text-600);
        background-color: var(--color-bg);
    }
