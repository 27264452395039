.c-footer {
    padding-top: rem(56);
    padding-bottom: rem(96);
    background-color: var(--color-footer-bg);
    color: var(--color-footer-text);
    font-weight: 250;
    @include font-sans;

    @include respond-above(sm) {
        padding-top: rem(88);
        padding-bottom: rem(64);
    }

    .o-grid {
        align-items: flex-end;

        > * + * {
            @include respond-below(sm) {
                display: flex;
                align-items: center;
                margin-top: var(--space-xs);

                .c-btn {
                    margin: 0 0 0 auto;
                }
            }
            
            @include respond-above(sm) {
                text-align: right;
            }
        }
    }

    a,
    .o-link__text span {
        text-decoration-color: var(--color-text-600);
       
        &:hover,
        &:focus,
        .o-icon:hover + span  {
            color: var(--color-white);
            text-decoration-color: var(--color-bg);
        }
    }

    .c-btn--light {
        background: transparent;
        color: var(--color-white);
        border-color: var(--color-white);

        @include hocus() {
        background: var(--color-white);
        color: var(--color-dark)
        }
    }
}

    .c-footer__lang  {

        .o-icon {
            opacity: .8;
            margin-right: var(--space-xxs);
        }

        @include hocus {

            .o-icon {
                opacity: 1;
            }
        }
    }

    .c-footer__text {
        @include body-sans-5;
        
        @include respond-above(xs) {
            @include body-sans-4;
        }
        
        > p:first-child {
            filter: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .o-icon {
            color: var(--color-danger-500);
        }
    }

    .c-footer__social-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--space-md);
        height: var(--space-md);
        margin: 0 rem(2);
        padding: rem(4);
        border-radius: rem(2);
        background: var(--color-footer-social);

        .o-icon {
            opacity: .8;
        }

        @include hocus {
            & {
                transform: translateY(rem(-1));
                background: var(--color-dark);
                color: var(--color-white)
            }

            .o-icon {
                opacity: 1;
            }  
        }
    }