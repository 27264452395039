.c-comments {
    list-style-type: none;
    padding: 0;

    .c-user-post__text {
        line-height: 1.3;
        font-weight: 350;
        @include body-sans-4;
    }
}

.c-comments__section {
    grid-column: 2/4;
    max-width: rem(790);

    @include respond-above(lg) {
        grid-column: 2;
    }
}