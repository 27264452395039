.c-user-post {
    position: relative;
    padding-left: rem(44);
}
    .c-user-post__header {
        @include body-sans-5;
    }
        .c-user-post__author {
            font-weight: 500;

            @media (max-width: 370px) {
                display: block;
            }

            &::after {
                @media (min-width: 370px)  {
                    content: '·';
                }
            }
        }
        .c-user-post__avatar {
            position: absolute;
            left: 0;
            top: rem(4);
        }

    .c-user-post__text {
        @include body-sans-3;
    }