.c-home-intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: var(--space-lg);
    border-bottom: rem(12) solid var(--color-text);

    > *:first-child {
        max-width: 100%;
    }
    
    > * + * {
        margin: var(--space-lg) 0 0 0;
    }

    @include respond-below(sm) {
        margin-top: 0;
    }

    @include respond-above(sm) {
        flex-direction: row;
        padding-bottom: rem(80);
        
        > *:first-child {
            width: 100%;
            max-width: 240px;
            margin-top: var(--space-xs);
        }
    
        > * + * {
            margin: 0 0 0 rem(48);
        }
    }
}

    .c-home-intro__hola {
        color: var(--color-text);
    }